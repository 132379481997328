//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
    phoneOTPValidationApi,
    resendPhoneVerification,
} from '../../api/signup/phoneVerification'
import { resetPasswordValidateApi } from '../../api/reset'
import Formbox from '../FormBox'
import FormTitleBar from '../FormTitleBar'
import ButtonComponent from '../Button'
import Toast from '../Toast'
import styles from './verification.module.scss'
import Confirmation from '../Confirmation'
import successImage from '../../assets/images/successImage.svg'
import { encodeUserInfo, setToken } from '../../utils/auth'
import OtpInput from '../InputFields/OtpInput'
import { errorHandler } from '../../utils/errorHandler'
import ActionModal from '../../components/ActionModal'
import { AppButton } from '../Button'
import { InlineLoader } from '../Loader'
import RemitaLogo from 'src/assets/images/remita-logo.svg'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'

const Verification = ({
    fullPhoneNumber = '',
    countDown,
    setCountDown,
    resendOtp,
    processType,
    identifier,
    handleChangePhone,
    showTextHandler,
    type,
    phoneNumber,
    page,
    closeModal,
    phoneVerified,
    totalInputBox,
    subheader,
    handlePrevious,
    handleNext,
    validateMandateActivationFetching = false,
    headerText,
    verificationType,
    toastError,
    validateMandateErrorMessage,
    onSuccess,
    otpMethod,
    email,
}) => {
    const {
        Wrapper,
        VerificationTitleWrapper,
        OtpResend,
        ButtonWrapper,
        LinkWrapper,
        OtpWrapper,
        LoadingWrapper,
        BtnLoading,
        BottomWrapper,
        BottomBtnWrapper,
    } = styles
    const [otpCode, setOtpCode] = useState({
        'otp-1': '',
        'otp-2': '',
        'otp-3': '',
        'otp-4': '',
        'otp-5': '',
        'otp-6': '',
    })

    const [otpCode2, setOtpCode2] = useState({
        'otp-1': '',
        'otp-2': '',
        'otp-3': '',
        'otp-4': '',
    })

    const [count, setCount] = useState(1)
    const [success, setSuccess] = useState(false)
    const [width, setwidth] = useState(20)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    useEffect(() => {
        const handleSlider = () => {
            if (success && width < 100) {
                setwidth(prev => prev + 10)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, success])

    const handleChange = e => {
        const { maxLength, value, name } = e.target
        const [fieldName, fieldIndex] = name.split('-')

        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < 6) {
                const nextSibling = document.querySelector(
                    `input[name=otp-${parseInt(fieldIndex, 10) + 1}]`,
                )

                if (nextSibling !== null) {
                    nextSibling.focus()
                }
            }
        }
        if (value === '' && e.key === 'Backspace') {
            const prevSibling = document.querySelector(
                `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`,
            )

            if (prevSibling !== null) {
                prevSibling.focus()
            }
            return
        }
        if (page === 'direct-debit') {
            setOtpCode2(prev => ({
                ...prev,
                [`${fieldName}-${fieldIndex}`]: value,
            }))
        } else {
            setOtpCode(prev => ({
                ...prev,
                [`${fieldName}-${fieldIndex}`]: value,
            }))
        }
    }
    const handleVerifyPhone = () => {
        validatePhoneRefetch()
    }
    const handleVerifyEmail = () => {
        validateEmailRefetch()
    }

    let history = useHistory()

    const hideNumber = callNumber => {
        let newNumber = ''
        for (let i = 0; i < callNumber?.length; i++) {
            if (i > 2 && i < 9) newNumber += '*'
            else newNumber += callNumber[i]
        }
        return newNumber
    }
    const hideEmail = email => {
        if (email?.includes('@')) {
            const preText = email?.slice(0, 3)
            const domain = email?.split('@').slice(1)
            return preText + '***@' + domain
        } else return email
    }

    const otp = Object.values(otpCode).join('')
    const otp2 = Object.values(otpCode2).join('')

    useOTPVerify({
        otp: Object.values(page === 'direct-debit' ? otpCode2 : otpCode),
        handleOtpSubmit:
            otpMethod === 'email' ? handleVerifyEmail : handleVerifyPhone,
    })

    // validate phone number
    const {
        isFetching: validatePhoneFetching,
        error,
        data,
        refetch: validatePhoneRefetch,
    } = useQuery(
        'validatePhone',
        () =>
            processType === 'forgotPassword'
                ? resetPasswordValidateApi('phone', {
                      phone_number: fullPhoneNumber,
                      otp,
                  })
                : phoneOTPValidationApi({
                      phone_number: fullPhoneNumber,
                      otp,
                      module: type === 'consumer' ? 2 : 1,
                  }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                if (onSuccess) {
                    onSuccess()
                } else {
                    if (page === 'personalInfo') {
                        closeModal()
                        setShowSuccessModal(true)
                        phoneVerified()
                    } else {
                        setSuccess(true)
                    }
                    type !== 'consumer' && showTextHandler(false)
                }
                setErrorMessage('')
            },
            onError: error => {
                const errorMessage = errorHandler(error?.response?.data)
                setErrorMessage(errorMessage?.[0])
            },
        },
    )
    // validate email
    const {
        isFetching: validateEmailFetching,
        error: validateEmailError,
        data: validateEmailData,
        refetch: validateEmailRefetch,
    } = useQuery(
        'validateEmail',
        () =>
            processType === 'forgotPassword'
                ? resetPasswordValidateApi('email', {
                      email: email,
                      otp,
                  })
                : phoneOTPValidationApi({
                      email: email,
                      otp,
                      module: type === 'consumer' ? 2 : 1,
                  }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                if (onSuccess) {
                    onSuccess()
                } else {
                    if (page === 'personalInfo') {
                        closeModal()
                        setShowSuccessModal(true)
                        emailVerified()
                    } else {
                        setSuccess(true)
                    }
                    type !== 'consumer' && showTextHandler(false)
                }
                setErrorMessage('')
            },
            onError: error => {
                const errorMessage = errorHandler(error?.response?.data)
                setErrorMessage(errorMessage?.[0])
            },
        },
    )

    //resend
    const {
        isLoading: resendLoading,
        error: resendError,
        refetch: resendRefetch,
        data: resendData,
    } = useQuery(
        'resendCode',
        () =>
            resendOtp
                ? resendOtp({
                      phone_number: fullPhoneNumber,
                      identifier,
                  })
                : resendPhoneVerification({
                      phone_number: fullPhoneNumber,
                  }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setSuccessMessage('New verification code sent')
                setErrorMessage('')
                setCountDown(60 * count)
            },
            onError: () => setErrorMessage(''),
        },
    )
    //resend email
    const {
        isLoading: resendEmailLoading,
        error: resendEmailError,
        refetch: resendEmailRefetch,
        data: resendEmailData,
    } = useQuery(
        'resendEmailCode',
        () =>
            resendOtp
                ? resendOtp({
                      email: email,
                      identifier,
                  })
                : resendPhoneVerification({
                      email: email,
                  }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setSuccessMessage('New verification code sent')
                setErrorMessage('')
                setCountDown(60 * count)
            },
            onError: () => setErrorMessage(''),
        },
    )

    useEffect(() => {
        const token = data?.data?.data?.reset_token
        if (token) {
            history.push({
                pathname: '/reset-password',
                state: {
                    resetToken: token,
                    verificationType: 'phone',
                    page: 'resetVerify',
                    phoneNumber: fullPhoneNumber,
                },
            })
        }
    }, [data])

    const handleResendCode = () => {
        if (otpMethod === 'email') {
            resendEmailRefetch()
        } else {
            resendRefetch()
        }

        setCount(prev => prev + 1)
    }

    const ConsumerProcessType = localStorage.getItem('processType')

    if (success && width === 100) {
        if (type === 'consumer') {
            encodeUserInfo(data)
            localStorage.setItem('sunfiToken', data?.data?.data?.token?.access)

            history.push('/consumer/workspace')
        } else {
            setToken(data?.data?.data?.token?.access)
            history.push({
                pathname: '/create-account',
                state: {
                    partnerDetails: data?.data?.data?.user,
                    from: 'intialSignup',
                },
            })
        }
    }

    const handleContinue = () => {
        handleNext(otp2)
        setOtpCode2({
            'otp-1': '',
            'otp-2': '',
            'otp-3': '',
            'otp-4': '',
        })
    }

    return (
        <>
            <ActionModal
                actionModalOpen={showSuccessModal}
                headerText="Verification Successful"
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionHandler={() => setShowSuccessModal(false)}
                actionType={'success'}
                actionText="Okay, Got it"
                noCancelBtn={true}
                onCancel={() => setShowSuccessModal(false)}
                closeModal={() => setShowSuccessModal(false)}
                closable
            />
            {success ? (
                <Confirmation
                    imgUrl={successImage}
                    header="Verification Successful"
                    subHeader={`${
                        type === 'consumer'
                            ? ConsumerProcessType === 'repaymentSchedule'
                                ? 'Give us a second to get your repayment breakdown ready'
                                : 'Give us a second to get your application details ready.'
                            : 'We’ve solved the problems that make it difficult for energy providers to transition'
                    }`}
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    scrollIndicator
                    scrollWidth={width}
                />
            ) : (
                <Formbox
                    height={
                        page === 'personalInfo' || page === 'direct-debit'
                            ? '100%'
                            : 'auto'
                    }
                >
                    <div
                        className={Wrapper}
                        style={{
                            marginTop: page === 'personalInfo' && '30px',
                        }}
                    >
                        <FormTitleBar
                            title={headerText || 'Verification Code'}
                            marginBottom={
                                page === 'personalInfo' ||
                                page === 'direct-debit'
                                    ? '-3px'
                                    : '-10px'
                            }
                        />
                        <div className={VerificationTitleWrapper}>
                            <p>
                                {subheader || 'Enter the OTP that was sent to '}
                                <b>
                                    {otpMethod === 'email'
                                        ? hideEmail(email)
                                        : hideNumber(phoneNumber)}
                                </b>
                            </p>
                        </div>
                        {error || toastError ? (
                            <Toast
                                message={
                                    errorMessage || validateMandateErrorMessage
                                }
                                messageType="error"
                            />
                        ) : resendData ? (
                            <Toast
                                message={successMessage}
                                messageType="success"
                            />
                        ) : (
                            ''
                        )}

                        <div
                            className={OtpWrapper}
                            style={{
                                padding: page === 'personalInfo' && '10px 40px',
                                justifyContent:
                                    page === 'direct-debit' && 'center',
                                marginTop: page === 'direct-debit' && '70px',
                            }}
                        >
                            <OtpInput
                                onChangeHandler={handleChange}
                                totalInputBox={totalInputBox}
                                value={
                                    page === 'direct-debit' ? otpCode2 : otpCode
                                }
                            />
                        </div>
                        {verificationType !== 'bank-card' && (
                            <div
                                className={OtpResend}
                                style={{
                                    marginTop:
                                        page === 'personalInfo' && '50px',
                                }}
                            >
                                <p style={{ marginBottom: '0px' }}>
                                    Didn’t receive the code ?
                                </p>
                                <p>
                                    {countDown < 1 ? (
                                        resendError ? (
                                            <p
                                                style={{
                                                    textAlign: 'center',
                                                    color: '#DA1E28',
                                                }}
                                            >
                                                {
                                                    resendError?.response?.data
                                                        ?.error?.phone_number[0]
                                                }
                                            </p>
                                        ) : (
                                            <span
                                                style={{
                                                    color: '#004AAD',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={
                                                    page === 'direct-debit'
                                                        ? resendOtp
                                                        : handleResendCode
                                                }
                                                disabled={resendLoading}
                                            >
                                                Resend Code
                                            </span>
                                        )
                                    ) : (
                                        `Resend Code (${
                                            countDown > 60
                                                ? `${Math.floor(
                                                      countDown / 60,
                                                  )}m ${countDown % 60}s`
                                                : `${countDown}s`
                                        })`
                                    )}
                                </p>
                            </div>
                        )}
                        {page === 'direct-debit' ? (
                            <>
                                <div className={BottomBtnWrapper}>
                                    <AppButton
                                        btnWidth="160px"
                                        btnBgColor="#E2EEFF"
                                        btnTextColor="var(--blue)"
                                        btnHeight="56px"
                                        handleClick={handlePrevious}
                                    >
                                        Previous
                                    </AppButton>
                                    &nbsp; &nbsp; &nbsp;
                                    <AppButton
                                        btnWidth="160px"
                                        btnBgColor="var(--blue)"
                                        btnTextColor="var(--white)"
                                        btnHeight="56px"
                                        handleClick={
                                            verificationType === 'bank-card'
                                                ? () => handleNext(otp2)
                                                : handleContinue
                                        }
                                        disabled={Object.values(otpCode2).some(
                                            input => input === '',
                                        )}
                                    >
                                        {validateMandateActivationFetching ? (
                                            <InlineLoader />
                                        ) : (
                                            'Continue'
                                        )}
                                    </AppButton>
                                </div>
                                <p className={BottomWrapper}>
                                    Powered by
                                    <img
                                        src={RemitaLogo}
                                        style={{
                                            marginLeft: '5px',
                                            marginTop: '-5px',
                                        }}
                                        alt="remita-logo"
                                    />
                                </p>
                            </>
                        ) : (
                            <>
                                <div className={ButtonWrapper}>
                                    <ButtonComponent
                                        btnBgColor="var(--blue)"
                                        btnTextColor="var(--white)"
                                        handleClick={
                                            otpMethod === 'email'
                                                ? handleVerifyEmail
                                                : handleVerifyPhone
                                        }
                                        disabled={Object.values(otpCode).some(
                                            input => input === '',
                                        )}
                                    >
                                        {validatePhoneFetching ? (
                                            <div className={LoadingWrapper}>
                                                <div
                                                    className={BtnLoading}
                                                ></div>
                                            </div>
                                        ) : (
                                            <>
                                                {otpMethod === 'email'
                                                    ? 'Verify Email'
                                                    : 'Verify Phone Number'}
                                            </>
                                        )}
                                    </ButtonComponent>
                                </div>
                                {page !== 'personalInfo' && (
                                    <div className={LinkWrapper}>
                                        <p>
                                            {otpMethod === 'email'
                                                ? 'Wrong Email? '
                                                : 'Wrong Phone Number? '}

                                            <span>
                                                <span
                                                    style={{
                                                        color: 'var(--blue)',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={handleChangePhone}
                                                >
                                                    {otpMethod === 'email'
                                                        ? 'Change Email'
                                                        : 'Change Number'}
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>{' '}
                </Formbox>
            )}
        </>
    )
}

export default Verification
