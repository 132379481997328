import PropTypes from 'prop-types'
import '../consumerkyc.scss'
import { ReactComponent as HelpIcon } from 'src/assets/images/help-circle.svg'
import { ReactComponent as SectionCompleteIcon } from 'src/assets/images/green-circle-tick.svg'
import { ReactComponent as SectionErrorIcon } from 'src/assets/images/red-error-circle.svg'
import arrow from 'src/assets/images/arrowDown.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import { useConsumerKYCContext } from '../context'
import { useAppContext } from 'src/appContext/AppContext'
import { getSectionCompletionData } from '../utils'
import { DotsLineLoader } from 'src/components/Loader'

const SectionContainer = ({
    title,
    children,
    isOpen,
    onToggle,
    info,
    displayProgress = true,
}) => {
    const {
        useType,
        inputs,
        errors,
        kycFetching,
        kycLoadingOrRefetchError,
        isAdminUser,
    } = useConsumerKYCContext()
    const { finanicalInfoFormik } = useAppContext()

    const { isComplete, requiredCount, completedCount, fields, hasError } =
        getSectionCompletionData(useType, title, inputs, {
            ...errors,
            ...finanicalInfoFormik.errors,
        })

    return (
        <div className="SectionContainer">
            <div
                className={`HeaderContainer ${isOpen ? 'isOpen' : ''}`}
                onClick={onToggle}
            >
                <h6 className="Heading">
                    {title}{' '}
                    {!isAdminUser && (
                        <CustomToolTip
                            toolTipPopupWidth="200px"
                            arrowPositionCenter
                            text={info}
                            toolTipMarginLeft="-98px"
                            toolTipFontSize="12px"
                            lineHeight="18px"
                            handleClick={event => {
                                event.stopPropagation()
                            }}
                        >
                            <HelpIcon className="OPKYC_HeadingHelpIcon" />
                        </CustomToolTip>
                    )}
                </h6>

                <div className="GroupItems ProgressToggle">
                    {kycFetching || kycLoadingOrRefetchError ? (
                        <div className="OnePageKYC_ProgressLoader">
                            <DotsLineLoader />
                        </div>
                    ) : (
                        <>
                            {displayProgress && (
                                <div className="ProgressContainer">
                                    <div className="GroupItems">
                                        <div className="GroupItems">
                                            {fields.map((field, index) => (
                                                <span
                                                    key={index}
                                                    className={
                                                        field.status ===
                                                        'complete'
                                                            ? 'active_fields'
                                                            : field.status ===
                                                              'error'
                                                            ? 'error_fields'
                                                            : ''
                                                    }
                                                ></span>
                                            ))}
                                        </div>
                                        {isComplete ? (
                                            <div className="SectionProgressStatusIconBox">
                                                <SectionCompleteIcon />
                                            </div>
                                        ) : hasError ? (
                                            <div className="SectionProgressStatusIconBox">
                                                <SectionErrorIcon />
                                            </div>
                                        ) : (
                                            <p>
                                                {completedCount}/{requiredCount}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                            <img
                                src={arrow}
                                alt="arrow"
                                className={
                                    isOpen ? 'TopArrowOpen' : 'TopArrowClosed'
                                }
                            />
                        </>
                    )}
                </div>
            </div>
            {isOpen && <>{children}</>}
        </div>
    )
}

SectionContainer.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    children: PropTypes.node,
    info: PropTypes.string,
    displayProgress: PropTypes.bool,
}
export default SectionContainer
