export const profileTypeData = [
    {
        name: 'Home or Stalls/Shops',
        value: 'RESIDENTIAL',
    },
    {
        name: 'Registered Business',
        value: 'BUSINESS',
    },
]

export const employmentTypeList = [
    {
        name: 'Fulltime, Part time or Contract',
        value: 'FULL_TIME',
    },
    {
        name: 'Self-employed',
        value: 'SELF_EMPLOYED',
    },
    {
        name: 'Retired',
        value: 'RETIRED',
    },
]

export const kycModuleMapping = {
    full_name: 'Full Name',
    phone_number_email: 'Phone number & email',
    address: 'Contact/installation address',
    employment_type: 'Employment type',
    pension_details: 'Pension Details',
    work_info: 'Where do you work',
    employee_data: 'Employee Data',
    employment_details: 'Employment Details',
    business: 'Business',
    avatar: 'Add image',
    id_card: 'ID card verification',
    bvn: 'Bank Verification Number',
    bank_statement: 'Bank Statement',
    repayment_method: 'Repayment Method',
    business_type: 'Business Type',
    financial_report: 'Financial Report',
    director_full_name: "Director's Full Name",
    director_phone_number_email: "Director's Phone Number & Email",
    director_bvn: "Director's BVN",
}

export const employmentTypeMapping = {
    FULL_TIME: 'full_employment',
    SELF_EMPLOYED: 'business',
    RETIRED: 'pension_details',
}

export const employmentTypeSelectValues = {
    full_employment: 'FULL_TIME',
    business: 'SELF_EMPLOYED',
    pension: 'RETIRED',
}
